body {
  margin: 0;


}
a{
  text-decoration: none !important;
 
}

.navbar{
  /* background:linear-gradient(to right, #6FC1A8, #45A4DC); */
  padding: 0px !important;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-menu{
  position: relative;
 
}
.links{
padding: 0px 10px;
}


.link-hover{
  color: black;
}
.nav-menu a{
  color: black;
  font-weight: 500;
  padding-left: 30px !important;
  padding-right: 10px !important;
}

.img-position{
  position: relative;
  top: -30%;
  left: 43.5%;
}
.card{
  padding: 15px !important;
}
/* Company-page css starting */
.company-heading{
  font-size: 40px;
  font-weight: 400;
}
.link-hover:hover{
  color: #45A4DC !important;

}
.link-hover:active{
  color: red !important;
}

/* Company-page css ending */

/* counter css */
.main_counter{
  margin-top: 5% !important;

}
.main_counter .counter
{
    /* background-color: #eaecf0; */
    text-align: center;
}
.main_counter .employees,.customer,.design,.order
{
    margin-top: 70px;
    margin-bottom: 70px;
}


.main_counter .employee-p,.customer-p,.order-p,.design-p
{
    font-size: 24px;
    color: #000000;
    line-height: 34px;
}
.main_counter {
  background-color: rgb(237,242,252);
  display: flex;
  justify-content: center;
}
.main_counter .col-lg-3{
  display: flex;
  justify-content: center;
}
.footer-row{
  background-color:#7a8b9c;
  display: flex;
  justify-content: center;
  padding: 0px 80px 0px 85px;

}
.footer-row .col-lg-6  div {
 margin-left: 15%;
 
}
.footer-row-2 .col-lg-5{
  color: white;
}
.footer-row-2 .col-lg-4{
  color: white;
}
.footer-row-2 .col-lg-5 p{
  color: #CACACA;

}
.footer-row-2 .col-lg-4 p{
  color:white;

}
.footer-row-2 .col-lg-2 {
  color: white !important;

}
.footer-row-2 .col-lg-2 p a{
  color: white;
}

.order-p{
  font-size: 16px !important;
  color: #60697B;
}
.employee-p{
  font-size: 16px !important;
  color: #60697B !important;
}
.customer-p{
  font-size: 16px !important;
  color: #60697B;

}
.design-p{
  font-size: 16px !important;
  color: #60697B;
}
.counter-count{
  color: #343F52 !important;
  font-weight: 600;
  font-size: 40px;
  

}
span{
  padding: 10px;
}
.row-div{
  margin-left: 10%;
  margin-top: 8%;
}
.footer-text{
  margin-left: 5%;
}
.fb-icon{
  margin-left: 2.5%;
}
.form{
  box-shadow: 5px 10px #888888;
}
p{

  word-spacing: 1.2px !important;
}
.btn-contact{

  outline: none!important;
  border: none;
  background: 0 0;
}
.navbar-collapse{
  display: flex;
  justify-content: end;
}
p{
  margin-bottom: 0px !important;
}
.our-vision-row{
  display: flex;
  justify-content: center;
}
.our-vision-row .card{
  min-height: 250px;
  border: none !important;
  background-color: none !important;
  
}
.our-vision-row p{
  color: white;

}
.our-vision-row h5{
  color: white;
}

.about-us p{
  text-align: justify;

}
.achievements p{
  text-align: justify;


}

.achievements li{
  
}
.career p{
  text-align: justify;

}
.our-vision-row .card{
  /* background-color: #0056A4; */
  background:linear-gradient(to right, #6FC1A8, #45A4DC);

  min-height: 320px;
}
.achi-p{
  text-align: justify;
  font-size: 15px;
}
.achi-h2{
  color: #794DFF;
  font-weight: 700;
}
.our-management .card:hover{
  transition: all 0.2s ease-out;
  box-shadow: 0px 10px 16px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;

  
}
h3{
  font-size: 40px !important;
  font-weight: 300 !important;
}
.glary-row-h3 h3{
  font-size: 30px !important;
  font-weight: 300 !important;

}
.text-p{
  font-size: 14px !important;
  margin-top: -10px;
}
.owl-dot span{
  padding: 0px !important;
  margin-top: 20px !important;
}
li{
  text-align: justify;
  /* margin-bottom: 5%; */
  position: relative;
}


.contry-p-tag .col-lg-2 p{
  font-size: 14px;
  margin-top: 5%;
}
.contry-p-tag a{
  color: black;
}


.brands h3 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  
}


hr {
  margin: 1rem 0;
  border: 0;
  opacity: 0.5 !important;
  border: 1px solid black !important;
  width: 75%;

  
}
.hr-div{
  display: flex;
  justify-content: center;
}
.hr-vision{
  width: 5% !important;
  margin-right: 9%;
  opacity: 1 !important;
  border: 1.5px solid rgb(1,86,164) !important;
  padding: 0px !important;
}
.our-values{
  margin-left: 25%;
}
.vision-title{
  margin-top: 9%;
}
td .btn{
  font-size: 10px !important;
}
b{
  font-weight: 600 !important;
}
.job-jd p{
  text-align: justify;
  font-size: 14px;
  

}
.job-jd{
  padding: 0px 40px 0px 40px !important;
}

.view-all{
  background-color: #9ac5b9 !important;
}


.para-left{
  margin-left: -1.8%;
}
.resume{
  border: none;
  width: 50%;
}
/* .navbar{
  background-color: rgba(0, 0, 0, .25);
} */

/* inside-Page Starting  */
.inside-col{
  margin: 3% 0% 3% 0% !important;
}
.inside-page-content{
    width: 95%;
    font-size: 14px;
    position: relative;
    left: 4%;
    text-align: justify;
    padding: 0px 30px 0px;
   
   
}
.img-link-names{
 
  padding: 10px;
 
 
}
.title-images{
  font-size: 28px !important;
}
.img-link-names a:hover{
  color: black !important;
}
.inside-page-col-link{
    margin: 3% 0% 3% 0% !important;
}
.inside-page-link-main p{
    position: relative;
}

.inside-page-links a{
    text-decoration: none;
    color: black;
    margin-left: 5%;
    
    
}
.inside-page-links a:hover{
    color: #fff;
}
.inside-page-links p{
    font-weight: 500;
    font-size: 14px;
    box-shadow: inset 0 0 0 0 #54b3d6;
    transition: color .6s ease-in-out, box-shadow .6s ease-in-out;
    padding: 5px 0px 0px 7px;

}

.inside-page-link-main{
    height: 100%;
    border-left: 1px solid black;
    width: 90%;
}


.inside-page-links p:hover{

    box-shadow: inset 300px 0 0 0 #54b3d6;;

   
    
}


.inside-page-content-col{
  padding:0px 50px 0px 50px;
}


/* inside-Page Ending  */

/* Media Querryes */

/* Media Querry 320 t0 375 starting */

@media screen and (min-device-width:320px)  and (max-device-width:375px) {
  .content-row .col-lg-11 {
    padding: 0px 15px !important;
  }
  .form-jd {
    padding: 0px 0px 0px 0px;
}
li {
  text-align: left;
  /* margin-bottom: 5%; */
  position: relative;
}

span {
  padding: 0px;
}

.form-color {
  background-color: rgb(160 235 210);
  padding: 20px !important;
}

.col-lg-7-carrer {
  padding: 0px 15px ;
}
  .link-tag-company{
    position: relative;
    left: 0% !important;
   }
   .main-banner-images{
    width: 100% !important;
  }
  .div-2 img{
    width: 100%;
  }
   .footer-row{
    display: flex;
    justify-content: flex-start ;
    padding: 0px 10px 0px 10px;
   }
   .get-in-toch{
    padding: 15px 0px 15px 0px;
   }
   .visti h5{
    margin: 0px !important;

   }
   p{
    font-size: 14px !important;
    word-spacing: 0px !important;
   }
   .flages{
    padding: 10px 0px 10px 0px;
   }
   .visti span{
    margin: 0px !important;
   }
   h3{
    font-size: 30px !important;
    text-align: center;
   
   }
   .business-heading{
    display: flex;
    justify-content: center;
    padding: 0px 0px 0px 0px !important;
   }
   .inside-page-content-col{
    padding:0px 10px 0px 10px !important;
  }
  
  h4{
    font-size: 20px !important;
    font-weight: 500;
    margin-bottom: 1% !important;
  }
  .navbar-collapse{
    display: grid;
    justify-content: start;
  }
  
  
  .ac1-image{
    width: 300px;
  }
  .h3-vision{
    font-size: 25px !important;
  }


  .card{
    margin: 5px 0px 5px 0px;
  }
  .h3-management{
    font-size: 25px !important;
  }
  .h3-abt{
    font-size: 25px !important;
  }
  .h3-brand{
    font-size: 25px !important;
  
  
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50% 50% !important;
}
.col-lg-6{
  padding: 0px !important;
}


.visti div {
  position: relative;
  right: 4.5%;
}
.owl-theme{
  margin: 0 !important;
}
.rounded-pill{
  width: 100% !important;
}
.counter-col{
  margin-bottom: 5%;
}
 
}

/* Media Querry 320 t0 375 ending */



.footer-row-2 .col-lg-4 p a{
  color: white;
}



/* Our-management start */

.our-management .col-lg-4{
  margin: 0px !important;
  padding: 0px 25px 0px 25px;
  
}

/* Our-Management end  */
.td-color-1{
  color: black !important;
}
.view-btn{
  --bs-btn-border-color: none !important;
  background-color:rgb(20 60 80) !important
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 1rem !important;
  height: 1rem !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}




/*Contact-page starting */
.contact-page-container{
  background-color: #F6F7FC ;
}
.contact-page-container .row-1{
  display: flex;
  justify-content: center;
}
.contact-icon-row .col-lg-4{
  padding-top: 10px !important;

}
.contact-page-container .row-2{
  display: flex;
  justify-content: center;
}
.circle-icon{
  background: white;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding:30px;
    font-size: 100px;
    color: #5DB5BF !important;
}
.contact-icon-row .col div h5{
  margin-top: 5%;
  font-size: 14px;

}
.contact-icon-row .col div p{
  font-size: 14px;
}
/* Contact-page ending */
 
@media screen and (min-device-width:376px)  and (max-device-width:991px) {
  .navbar-collapse{
    display: grid;
    justify-content: start;
  }
  .link-tag-company{
    position: relative;
    left: 30%;
   }
   .main-banner-images{
    width: 100%;
  }
  .div-2 img{
    width: 100%;
  }
  
}



@media screen and (min-device-width:376px)  and (max-device-width:767px) {

  .content-row .col-lg-11 {
    padding: 0px 15px !important;
  }
  .form-jd {
    padding: 0px 0px 0px 0px !important;
}

span {
  padding: 0px;
}
.form-color {
  background-color: rgb(160 235 210);
  padding: 20px !important;
}


li {
  text-align: left;
  /* margin-bottom: 5%; */
  position: relative;
}
.col-lg-7-carrer {
  padding: 0px 15px !important;
}
  

  .link-tag-company{
    position: relative;
    left: 0% !important;
   }
   .main-banner-images{
    width: 100%;
  }
  .div-2 img{
    width: 100%;
  }
   .footer-row{
    display: flex;
    justify-content: flex-start ;
    padding: 0px 10px 0px 10px;
   }
   .get-in-toch{
    padding: 15px 0px 15px 0px;
   }
   .visti h5{
    margin: 0px !important;

   }
   p{
    font-size: 14px !important;
    word-spacing: 0px !important;
   }
   .flages{
    padding: 10px 0px 10px 0px;
   }
   .visti span{
    margin: 0px !important;
   }
   h3{
    font-size: 30px !important;
    text-align: center;
   
   }
   .business-heading{
    display: flex;
    justify-content: center;
    padding: 0px 0px 0px 0px !important;
   }
   .inside-page-content-col{
    padding:0px 10px 0px 10px !important;
  }
  
  h4{
    font-size: 20px !important;
    font-weight: 500;
    margin-bottom: 1% !important;
  }
  .navbar-collapse{
    display: grid;
    justify-content: start;
  }
  
  
  .ac1-image{
    width: 300px;
  }
  .h3-vision{
    font-size: 25px !important;
  }


  .card{
    margin: 5px 0px 5px 0px;
  }
  .h3-management{
    font-size: 25px !important;
  }
  .h3-abt{
    font-size: 25px !important;
  }
  .h3-brand{
    font-size: 25px !important;
  
  
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50% 50% !important;
}
.col-lg-6{
  padding: 0px !important;
}
.col-lg-7-carrer{
  padding: 0px 10px 0px 10px!important;
}
.form-jd{
  padding: 0px 10px 0px 10px ;
}
  
}
.home-about{
  background-color:white !important;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border:  1px solid linear-gradient(to right, #6FC1A8, #45A4DC) ;
}

.col-about-content{

  padding: 10px;
  border-radius: 10px;
  color:black;
}
/* .col-about-content div{
  padding: 50px;
} */
.rounded-pill{
  background-color: rgb(198, 145, 47);
  border: none !important;
  width: 40%;
  padding: 10px !important;
  
}
.rounded-pill a {
  text-decoration: none;
  color: white;
}
.home-about-row{
  display: flex;
  justify-content: center;
  margin-top: 5% !important;
  margin-bottom: 5%;
}
.about-comtent{
  margin-top: 5%;
  margin-bottom: 5% !important;
}
.h3-vision{
  text-align: center;
  margin-bottom: 3%;
}
.vision-our{
  text-align: center;
}
.vision-our svg{
  font-size: 70px;
  color: white;
}
.mission-our{
  text-align: center;
}
.mission-our span{
  margin-top: 2%;

}
.mission-our h5{
  margin-top: 9%;
}
.mission-our span svg{
  font-size: 60px;
  color: white;
}
.value-our{
  text-align: center;
}
.value-our svg{
  color: white;
  font-size: 70px;

}
.value-our h5{
  margin-top: 9%;
}
/* // .home-about p {
//   color: rgb(175, 184, 190);
// } */
.term-and-condion h2{
  font-size: 30px;
  font-weight: 300;
  margin:  15px 0px;
}


/* About-page start */

.About-carousel{
  padding: 0px !important;
}

.about-us-content{
  margin-top: 2%;
  margin-bottom: 5%;
}
.about-us-content .row{
  display: flex;
  justify-content: center;
}
.about-us-content .row .col-lg-6{
  margin-top: 2.2%;
  padding: 0px 40px 0px 40px;

}
.about-us-content .row .col-lg-6 p{
  text-align: justify;

}
/* About-page end */

/* our-business page start */

.flags-row{
  display: flex;
  justify-content: center;
}
.flags-row .col-lg-4{
  padding: 0px 0px 0px 40px ;
}
.flags-row .col-lg-7{
  padding: 0px 25px 0px 25px;
}
.flages-new-css{
  display: grid;
  justify-content: center;
  align-items: center;
}
.hr-line{
  display: flex;
  justify-content: center;
}

.contry-p-tag .col-lg-5 p{
  font-size: 18px;
  text-align: justify;
  margin-bottom: 15px !important;

}
.link-tag-company{
  position: relative;
  left: 17%;
 }

.inside-page{
  padding: 0px !important;
}
.inside-page-2 .row .carousel{
  padding: 0px;
}
.inside-page-2 .row .carousel .carousel-item{
  width: 100%;
  height: auto;
}
.inside-page-3 .row .col-lg-12{
padding: 0px 50px;
text-align: justify;
}

/* Our-business page end */



/* Career-page start */
.career .carousel-row .carousel{
  padding: 0px;

}
.content-row {
  display: flex;
  justify-content: center;
}
.content-row .col-lg-11{
  padding: 0px 40px;
}
.table-head{
background-color: rgb(70,165,219);
color: white;

}
.table-body {
  background-color: #fefbfb;
}
.view-description-btn{
  background-color: rgb(20 60 80);
}
.capitalize{
  text-transform: capitalize;
}

.application-container{
  margin-top: 5%;
}
.col-lg-7-carrer{
  padding: 0px 40px ;
}
.form-jd{
  padding: 0px 30px 0px 0px ;
}
.form-color{
  background-color: rgb(160 235 210);
  padding: 40px;
}
.form-color h6{
  font-size: 30px;
}
.form-label-color{
  color: red;
  margin: 0px;
}
.submit-btn {
  background-color: #46A5DB;

}
.form-label-green{
  color: green;
}
/* Career-page end */


/* Achivement-page start */

.achivement-page{
  padding: 0px !important;
}
.achivement-page .carousel{
  padding: 0px;
}
.achivement-page-content-row{
  display: flex;
  justify-content: center;
}
.achivement-page-content-row .col-lg-6{
  margin-top: 2.2%;
  padding: 0px 40px ;
  text-align: justify;
}
/* Achivement-page  end */

/* Gallary-page start */

.felids-row{
  display: flex;
  justify-content: center;
}
.main-row{
  display: flex;
  justify-content: center;
}
.glary-row-h3{
  display: flex;
  flex-wrap: wrap;
}
.main-col{
  margin-bottom: 15px;
}
.galary-images{
  object-fit: cover;
  cursor: pointer;
}

/* Gallary-page end */


/* Homepage start */
.home-about .row{
  padding: 0px !important;
}
.home-about .row .carousel-them{
 padding: 20px;

}
.owl-theme .div-1{
  display: block;
  align-items: center;
  align-content: center;
}
.owl-theme .div-2{
  display: flex;
  justify-content: center;

  
}
.owl-theme .div-2 img{
  border-radius: 0px;
}
.our-vision-row .col-lg-3{
  padding: 10px 10px 0px 10px;

}
.our-vision-row .col-lg-3 .card{
  height: 1005;
}
.our-vision-row .col-lg-3 .card h5{
  text-decoration: underline;
}
.home-value-li{
  color: white;
}
.main_counter{
  margin-top: 9.9% ;
}
.last-row{
  padding: 0px !important;
}
.last-row .col{
  padding: 0px !important;
}
.barnd-img{
padding-left: 15px;
padding-right: 15px;
margin-top: 10%;
}
.brand-container{
  margin-top: -10%;
}
.main-banner-images{
  width: 100%;
}
.div-2 img{
  width: 100%;
}
/* Homepage end */



/* Spinner css start */

.spinner-css{
  margin: 11% 0% 11% 0% !important;
}
/* Spinner css  end */
.count-number svg{
  font-size: 20px ;
}